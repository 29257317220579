import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import Popup from 'reactjs-popup';
import "./requisiteqrcode.scss";


const Requisiteqrcode = ({ qrCodeData }) => {
  return (
    <Popup trigger={<button>Open QR Code</button>} modal nested>
      {(close) => (
        <div className="modal">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header">QR Code</div>
          <div className="content">
            <QRCode value={qrCodeData} />
          </div>
        </div>
      )}
    </Popup>
  );
};

export default Requisiteqrcode;



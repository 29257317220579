import React, { Component, useState } from "react";
import "./requisites.scss";
import Button from "@components/button";
import ButtonBootstrap from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ModalBody from "react-bootstrap/ModalBody";
import logo from "@img/logo.svg";
import Requisiteqrcode from "../requisiteqrcode";

import { QRCodeSVG } from "qrcode.react";

import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
	return (
		<Modal {...props} centered size="sm" dialogClassName="custom-modal-width">
			<Modal.Body centered>
				<QRCodeSVG
					value={
						'ST00012|Name=АО "ТРАНСПОРТНАЯ КАРТА"|PersonalAcc=40702810400020003396|BankName=ПАО "АК БАРС" БАНК|BIC=049205805|CorrespAcc=30101810000000000805|PayeeINN=1660096477|KPP=166001001'
					}
					size={256}
				/>
			</Modal.Body>
			<Modal.Footer>
				<ButtonBootstrap onClick={props.onHide}>Закрыть</ButtonBootstrap>
			</Modal.Footer>
		</Modal>
	);
}

const requisites = [
	{ title: "Год основания", value: "12/2006" },
	{ title: "Генеральный директор", value: "Эйдлин Денис Григорьевич" },
	{
		title: "Расчетный счет в ПАО «АК БАРС» БАНК",
		value: "40702810400020003396"
	},
	{
		title: 'Корреспондентский счет в ПАО "АК БАРС" БАНК',
		value: "30101810000000000805"
	},
	{ title: "ИНН", value: "1660096477" },
	{ title: "КПП", value: "166001001" },
	{ title: "БИК", value: "049205805" },
	{
		title: "Юридический адрес",
		value: "Россия, Республика Татарстан, 420087, г.Казань, ул.Крылова, д.3"
	},
	{ title: "Телефон", value: "+7 (843) 262-80-80" },
	{ title: "Факс", value: "+7 (843) 261-17-79" },
	{ title: "E-mail", value: "office@transkart.ru" },
	{ title: "Сайт", value: "https://transkart.ru" }
];

const currentYear = new Date().getFullYear();

const print = () => {
	window.print();
};

const Requisites = () => {
	const [modalShow, setModalShow] = React.useState(false);

	return (
		<section className="full-width requisites">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Реквизиты</h2>
					</div>
					<div className="container" />
					<div className="section-print col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<img
							className="logo-print"
							src={logo}
							alt="Логотип транспортной карты"
						/>
						<h4>Транспортная карта</h4>
						<ul className="simple-table">
							{requisites.map(({ title, value }) => (
								<li key={value} className="simple-table__item">
									<div className="simple-table__item__title">
										<p>{title}</p>
									</div>
									<div className="simple-table__item__value">
										<p>{value}</p>
									</div>
								</li>
							))}
						</ul>
						<p className="copyright">АО «Транспортная карта» {currentYear}</p>
					</div>

					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<Button onClick={print} className="button" title="Распечатать" />
						<Button onClick={() => setModalShow(true)} title="QR код" />
					</div>

					<MyVerticallyCenteredModal
						show={modalShow}
						onHide={() => setModalShow(false)}
					/>
				</div>
			</div>
		</section>
	);
};

export default Requisites;
